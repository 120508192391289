/* You can add global styles to this file, and also import other style files */
@import 'material-icons/iconfont/outlined.css';
@import 'primeng/resources/themes/bootstrap4-light-blue/theme.css';

.section-wrapper {
  background-color: white;
  border-radius: 16px;
  padding: 24px 24px 40px 24px;
  gap: 32px;
}

.page-wrapper {
  background-color: transparent;
  padding: 64px 32px 0px 32px;
  gap: 24px;
}

.bold {
  font-weight: 700;
}

.light-bold {
  font-weight: 500;
}

.pointer {
  cursor: pointer;
}

.fit-content {
  width: fit-content;
}

.header-text-3 {
  font-size: 1.625rem;
  font-weight: 400;
}

.header-text-4 {
  font-size: 1.375rem;
  font-weight: 700;
}

.bold-text-l {
  font-size: 1.375rem;
  font-weight: 700;
  color: #222b2d;
}

.bold-text-s {
  font-size: 1rem;
  font-weight: 700;
  color: #373649;
}

.bold-text-xs {
  font-size: 0.875rem;
  font-weight: 700;
  color: #374649;
}

.regular-text-xs {
  font-size: 0.875rem;
  font-weight: 400;
  color: #374649;
}

.regular-text-xs-bold {
  font-size: .875rem;
  font-weight: 700;
  color: #222b2d;
}

.regular-text-s {
  font-size: 1rem;
  font-weight: 400;
  color: #222b2d;
}

.regular-text-bold {
  font-size: 1.125rem;
  font-weight: 400;
  color: #222b2d;
}

.tooltip-info-unavailability .p-tooltip-text {
  width: max-content;
  background-color: #FF9789 !important;
  color: #222B2D;
  border: 1px solid #222B2D;
}

.tooltip-info-unavailability-edit .p-tooltip-text {
  width: max-content;
  background-color: #D9D9D9 !important;
  color: #222B2D;
  border: 1px solid #222B2D;
}

.tooltip-free-slots .p-tooltip-text {
  width: max-content;
  background-color: #FFFFFF !important;
  color: #222B2D;
  border: 1px solid #222B2D;
}

.tooltip-info-planning-table .p-tooltip-text {
  width: max-content;
  background-color: #F7F9FA !important;
  color: #222B2D;
  border-style: solid;
  border-color: #222B2D;
  border-width: 1px;
}

.p-overlaypanel .p-overlaypanel-close {
  top: 0.75rem;
  right: 1rem;
  background: none;
  color: #374649;
}

.unavailabilities-panel .p-overlaypanel-content {
  width: 45.5rem;
  border: 1px solid #222B2D;
  background: linear-gradient(0deg, #F7F9FA, #F7F9FA), linear-gradient(0deg, #222B2D, #222B2D);
}

.p-calendar .p-icon {
  color: #ED0000;
}

.arrow {
  font-size: 4rem;
}

.small-icon-size {
  font-size: 1rem;
}

.large-icon-size {
  font-size: 2rem;
}

.truncated-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  width: 100%;
  overflow: hidden;
}

.p-dialog-header {
  border: none !important;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  padding: 24px;
  height: 80px;
}

.p-dialog-footer {
  border: none !important;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  padding: 24px;
}

.p-confirm-dialog-accept
{
  margin-left: 1rem;
}

.p-dialog {
  border-radius: 10px !important;
  border: none !important;
}

.p-confirm-dialog .p-dialog-content {
  padding: 0px 24px 0px 24px;
}

.p-dialog.p-confirm-dialog .p-confirm-dialog-message {
  margin-left: 0;
}
